import { twMerge } from 'tailwind-merge';

import type { PropsWithChildren } from 'react';

type BadgeColors = 'pink' | 'white' | 'gray' | 'green' | 'brown';
type BadgeSizes = 'sm' | 'md' | 'lg';
type BadgeOutlineColor = 'none' | 'green' | 'gray';

export interface BadgeProps extends PropsWithChildren {
  className?: string;
  color: BadgeColors;
  isRounded?: boolean;
  size?: BadgeSizes;
  outlineColor?: BadgeOutlineColor;
  isThemed?: boolean;
}

export const badgeBaseStyles =
  'inline-block whitespace-nowrap align-middle text-ellipsis max-w-full overflow-hidden';

export const colorVariants: { [key in BadgeColors]: string } = {
  pink: 'bg-pink-800 text-brown-900',
  white: 'bg-white text-brown-900',
  gray: 'bg-gray-200 text-brown-900',
  green: 'bg-green-800 text-brown-900',
  brown: 'bg-brown-900 text-white',
};

export const sizeVariants: { [key in BadgeSizes]: string } = {
  sm: 'text-xs px-2.5 py-0.5',
  md: 'text-sm px-4 py-3.5',
  lg: 'text-base px-3.5 py-1.5',
};

export const outlineVariants: { [key in BadgeOutlineColor]: string } = {
  none: '',
  green: 'ring-1 ring-green-900',
  gray: 'ring-1 ring-gray-500',
};

export const Badge = ({
  children,
  className,
  color,
  isRounded = false,
  size = 'md',
  outlineColor = 'none',
  isThemed = false,
  ...props
}: BadgeProps) => {
  return (
    <span
      className={twMerge(
        badgeBaseStyles,
        isThemed
          ? ''
          : `${colorVariants[color]} ${sizeVariants[size]} ${outlineVariants[outlineColor]}`,
        isRounded ? 'rounded-full' : '',
        className
      )}
      data-testid="aslan-badge"
      {...props}
    >
      {children}
    </span>
  );
};
export default Badge;
